import React, {useContext, useLayoutEffect, useRef} from 'react';
import './Contacts.scss'
import Heading from "../atoms/Heading/Heading";
import ContactCard from "../atoms/ContactCard/ContactCard";
import {TranslateContext} from "../../App";


const Contacts = (props: { animation: boolean }) => {
        const translate = useContext(TranslateContext);

        const managers = [
            {
                name: translate.contacts.card1.name,
                country: translate.contacts.card1.country,
                lang: translate.contacts.card1.lang,
                avatar: '',
                contact: {
                    phone: translate.contacts.card1.phone, mail: translate.contacts.card1.email, contactNumber: translate.contacts.card1.contactNumber
                }
            },

        ]


        const cards = useRef<HTMLDivElement>(null);
        const heading = useRef<HTMLDivElement>(null);

        let observer = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (cards.current && heading.current) {
                    if (entry.intersectionRatio > 0.1) {
                        cards.current.style.transform = `translate3d(0,0,0)`
                        heading.current.style.opacity = '1'
                    }

                    if (entry.intersectionRatio < 0.9) {
                        cards.current.style.transform = `translate3d(100vw,0,0)`
                        heading.current.style.opacity = '0'
                    }
                }
            });
        });


        useLayoutEffect(() => {
            if (props.animation) {
                let elShoe = document.querySelector('.contacts');
                // @ts-ignore
                observer.observe(elShoe);
            }
        })


        return (
            <div className='contacts' id='contacts'>
                <div className='contacts-wrapper'>
                    <div className={props.animation ? 'contacts-heading' : 'contacts-heading non-animation'}  ref={heading}>
                        <Heading text={translate.contacts.heading} color={'white'}/>
                    </div>
                    <div className={props.animation ? 'contacts-cards' : 'contacts-cards non-animation'}  ref={cards}>
                        <ContactCard {...managers[0]}/>
                    </div>
                </div>
            </div>

        );
    }
;

export default Contacts;
