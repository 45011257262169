import React, { useContext, useState } from 'react';
import './Reviews.scss'
import { TranslateContext } from "../../App";
import Heading from '../atoms/Heading/Heading';
import ReviewCard from './ReviewCard/ReviewCard';

export type ReviewModel = {
    description: string;
    author: string;
    project: string;
}

const Reviews = (props: {animation: boolean}) => {
    const translate = useContext(TranslateContext);

    const [activeReviewIndex, setActiveReviewIndex] = useState(0)

    const reviews: ReviewModel[] = [
        {
            description: translate.reviews.review1.description,
            author: translate.reviews.review1.author,
            project: translate.reviews.review1.project,
        },
        {
            description: translate.reviews.review2.description,
            author: translate.reviews.review2.author,
            project: translate.reviews.review2.project,
        },
    ]

    return (
        <div id='reviews' className='reviews-wrapper'>
            <div className='reviews-heading'>
                <Heading text={translate.reviews.heading}/>
            </div>

            <div className='buttons-wrapper'>
                {reviews.map((review, index) => {
                    return (
                        <button key={index}
                                className={activeReviewIndex === index ? 'button-active' : 'button-blue'}
                                onClick={() => setActiveReviewIndex(index)}>
                            {review.project}
                        </button>
                    )
                })}
            </div>

            <ReviewCard review={reviews[activeReviewIndex]}/>
        </div>
    );
};

export default Reviews;
