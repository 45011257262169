import React, { useContext, useLayoutEffect, useRef } from 'react';
import './Payback.scss'
import Heading from "../atoms/Heading/Heading";
import { TranslateContext } from "../../App";

const Payback = (props: { animation: boolean }) => {
    const translate = useContext(TranslateContext);

    const heading = useRef<HTMLDivElement>(null);
    const img = useRef<HTMLDivElement>(null);
    const textOne = useRef<HTMLDivElement>(null);
    const textTwo = useRef<HTMLDivElement>(null);
    const numberOne = useRef<HTMLDivElement>(null);
    const numberTwo = useRef<HTMLDivElement>(null);

    let observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            const heightForRemove = entry.boundingClientRect.height / 6
            if (heading.current && img.current && textOne.current && textTwo.current && numberOne.current && numberTwo.current) {
                if (entry.intersectionRatio > 0.2 && entry.boundingClientRect.y > 0) {
                    // info.current.style.opacity = '1'
                    heading.current.style.opacity = `1`
                }

                if (entry.intersectionRatio > 0.4 && entry.boundingClientRect.y > 0) {
                    img.current.style.transform = 'translate3d(0,0,0)'
                    textOne.current.style.transform = 'translate3d(0,0,0)'
                    textTwo.current.style.transform = 'translate3d(0,0,0)'
                    numberOne.current.style.opacity = '1'
                    numberTwo.current.style.opacity = '1'
                }

                if (entry.intersectionRatio < 0.8) {
                    heading.current.style.opacity = `0`
                    numberOne.current.style.opacity = '0'
                    numberTwo.current.style.opacity = '0'
                    textOne.current.style.transform = 'translate3d(100vw,0,0)'
                    textTwo.current.style.transform = 'translate3d(100vw,0,0)'
                    img.current.style.transform = 'translate3d(-100vw,0,0)'
                }
            }
        });
    });


    useLayoutEffect(() => {
        if (props.animation) {
            let elShoe = document.querySelector('.payback-wrapper');
            // @ts-ignore
            observer.observe(elShoe);
        }
    })


    return (
        <div id='payback' className='payback-wrapper'>
            <div ref={heading} className={props.animation ? 'payback-heading' : 'payback-heading non-animation'}>
                <Heading text={translate.payback.headingCost}/>
            </div>

            <div className='payback'>
                <div className='payback-info cost-info'>
                    <ul role="list" className='payback-list left'>
                        <li>
                            <div className={props.animation ? 'sub-tittle' : 'sub-tittle non-animation'}
                                 ref={textTwo}> {translate.payback.point3.description}</div>
                            <div ref={numberTwo} className={props.animation ? 'number-wrapper' : 'number-wrapper non-animation'}>
                                <span className="big-text">от</span><span className='number'>{translate.payback.point3.number}</span>
                                <span className="big-text">млн руб.</span>
                            </div>
                            <div className="additional-text non-animation">{translate.payback.point3.additional}</div>
                        </li>
                    </ul>

                    <ul role="list" className='payback-list right'>
                        <li>
                            <div className={props.animation ? 'sub-tittle' : 'sub-tittle non-animation'}
                                 ref={textTwo}> {translate.payback.point5.description}</div>
                            <div ref={numberTwo} className={props.animation ? 'number-wrapper' : 'number-wrapper non-animation'}>
                                <span className="big-text">от</span><span className='number'>{translate.payback.point5.number}</span>
                                <span className="big-text">млн руб.</span>
                            </div>
                        </li>
                        <li>
                            <div className={props.animation ? 'sub-tittle' : 'sub-tittle non-animation'}
                                 ref={textTwo}> {translate.payback.point4.description}</div>
                            <div ref={numberTwo} className={props.animation ? 'number-wrapper' : 'number-wrapper non-animation'}>
                                <span className="big-text">от</span><span className='number'>{translate.payback.point4.number}</span>
                                <span className="big-text">млн руб.</span>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className='ps-text-wrapper'>
                    <p className='ps-text'>
                        <i>{translate.payback.ps1}</i>
                    </p>
                    <p className='ps-text'>
                        <i>{translate.payback.ps2}</i>
                    </p>
                </div>
            </div>

            <div ref={heading} className={props.animation ? 'payback-heading' : 'payback-heading non-animation'}>
                <Heading text={translate.payback.headingPayback}/>
            </div>

            <div className='payback'>
                <div className='payback-info'>
                    <ul role="list" className='payback-list'>
                        <li>
                            <div className={props.animation ? 'sub-tittle' : 'sub-tittle non-animation'}
                                 ref={textTwo}> {translate.payback.point2.description}</div>
                            <div ref={numberTwo} className={props.animation ? 'number-wrapper' : 'number-wrapper non-animation'}>
                                <span className="big-text">от</span><span className='number'>{translate.payback.point2.number}</span><span
                                className="big-text">%</span>
                            </div>
                        </li>
                        <li>
                            <div className={props.animation ? 'sub-tittle' : 'sub-tittle non-animation'}
                                 ref={textOne}>{translate.payback.point1.description}</div>
                            <div ref={numberOne} className={props.animation ? 'number-wrapper' : 'number-wrapper non-animation'}>
                                <span className="big-text">до</span> <span className='number'>{translate.payback.point1.number}</span><span
                                className="big-text">%</span>
                            </div>
                        </li>
                    </ul>
                </div>

                <div ref={img} className={props.animation ? 'payback-img' : 'payback-img non-animation'}>
                    <img src='/images/payback.png'/>
                </div>
            </div>
        </div>

    );
};

export default Payback;
