import React, {useContext} from 'react';
import './ScreenshotsAndTechnologies.scss'
import {TranslateContext} from "../../App";
import Heading from '../atoms/Heading/Heading';

const ScreenshotsAndTechnologies = (props: {animation: boolean}) => {
    const translate = useContext(TranslateContext);

    return (
        <div className='screenshots-and-technologies-wrapper'>
            <div className='screenshots-and-technologies-heading'>
                <Heading text={translate.technologies.heading}/>
            </div>

            <p className='screenshots-and-technologies-description'>
                {translate.technologies.description}
            </p>

            <div className='screenshots'>
                <img src={'/images/ui1.jpg'}/>
                <img src={'/images/ui2.jpg'}/>
                <img src={'/images/ui3.jpg'}/>
            </div>

            <div>
                <p className='technologies-label'>
                    <b>{translate.technologies.programmingLanguagesLabel}:</b>
                </p>
                <p className='technologies-list'>
                    {translate.technologies.programmingLanguages}
                </p>

                <p className='technologies-label'>
                    <b>{translate.technologies.frameworksLabel}:</b>
                </p>
                <p className='technologies-list'>
                    {translate.technologies.frameworks}
                </p>

                <p className='technologies-label'>
                    <b>{translate.technologies.librariesLabel}:</b>
                </p>
                <p className='technologies-list'>
                    {translate.technologies.libraries}
                </p>
            </div>
        </div>
    );
};

export default ScreenshotsAndTechnologies;
