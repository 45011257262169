import React from 'react';
import './ProjectCard.scss'
import { ProjectModel } from '../Projects';


const ProjectCard = (props: {project: ProjectModel}) => {
    return (
        <div className='project-card-wrapper'>
            <img className='project-image' src={props.project.image}/>

            <div>
                <h4 className='project-name'>
                    {props.project.name}
                </h4>

                <p className='project-description'>
                    {props.project.description}
                </p>

                <p className='project-results-label'>
                    <b>{props.project.resultsLabel}</b>
                </p>

                <p className='project-results'>
                    {props.project.results}
                </p>
            </div>
        </div>
    );
};

export default ProjectCard;
