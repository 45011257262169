import React, { useContext } from 'react';
import './Projects.scss'
import {TranslateContext} from "../../App";
import Heading from '../atoms/Heading/Heading';
import ProjectCard from './ProjectCard/ProjectCard';

export type ProjectModel = {
    name: string;
    description: string;
    resultsLabel: string;
    results: string;
    image: string;
}

const Projects = (props: {animation: boolean}) => {
    const translate = useContext(TranslateContext);

    const projects: ProjectModel[] = [
        {
            name: translate.projects.project1.name,
            description: translate.projects.project1.description,
            resultsLabel: translate.projects.project1.resultsLabel,
            results: translate.projects.project1.results,
            image: translate.projects.project1.image,
        },
        {
            name: translate.projects.project2.name,
            description: translate.projects.project2.description,
            resultsLabel: translate.projects.project2.resultsLabel,
            results: translate.projects.project2.results,
            image: translate.projects.project2.image,
        }
    ]

    return (
        <div id='projects' className='projects-wrapper'>
            <div className='projects-heading'>
                <Heading text={translate.projects.heading} color='white'/>
            </div>

            <div className='projects'>
                {projects.map(project => {
                    return <ProjectCard project={project} key={project.name}/>;
                })}
            </div>
        </div>
    );
};

export default Projects;
