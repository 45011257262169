import React, { useContext } from 'react';
import './Footer.scss'
import LogoIcon from "../../assets/icons/LogoIcon";
import { TranslateContext } from '../../App';

const Footer = (props: {animation: boolean}) => {
    const translate = useContext(TranslateContext);

    return (
        <div id='contacts' className='footer'>
            <div className='footer-logo'>
                <LogoIcon/>
            </div>

            <p className='footer-project-info'>
                {translate.footer.projectInfo}
            </p>

            {/* <Link className='footer-documents' to={'/documents'}> */}
            {/*     {translate.documents.link} */}
            {/* </Link> */}

            {/* <div className='footer-contacts'> */}
            {/*     <a href="mailto:info@solut-atomic.ru"> */}
            {/*         info@solut-atomic.ru */}
            {/*     </a> */}
            {/* </div> */}
        </div>
    );
};

export default Footer;
