import React, {useContext, useLayoutEffect, useRef, useState} from 'react';
import './Charts.scss'
import Heading from "../atoms/Heading/Heading";
import ChartOne from "./ChartOne/ChartOne";
import ChartTwo from './ChartTwo/ChartTwo';
import ChartThree from './ChartThree/ChartThree';
import {TranslateContext} from "../../App";


const Charts = (props: { animation: boolean }) => {
    const translate = useContext(TranslateContext);

    const [chartState, setChart] = useState(0)
    // const description = useRef<HTMLDivElement>(null);
    // const heading = useRef<HTMLDivElement>(null);
    //
    //
    // let observer = new IntersectionObserver(function (entries) {
    //     entries.forEach(function (entry) {
    //         if (description.current &&  heading.current) {
    //             if (entry.intersectionRatio > 0.2) {
    //                 description.current.style.transform = `translate3d(0,0,0)`
    //                 heading.current.style.transform = `translate3d(0,0,0)`
    //             }
    //
    //             if (entry.intersectionRatio < 0.8) {
    //                 description.current.style.transform = `translate3d(100vw,0,0)`
    //                 heading.current.style.transform = `translate3d(-100vw,0,0)`
    //             }
    //         }
    //     });
    // });


    // useLayoutEffect(() => {
    //     let elShoe = document.querySelector('.other');
    //     // @ts-ignore
    //     observer.observe(elShoe);
    // })

    return (
        <div className='charts' id='charts'>
            <div className='charts-wrapper'>
                <div className='charts-heading'>
                    <Heading text={translate.charts.heading}/>

                    <div className='buttons-wrapper'>
                        <button key={Math.random()}
                                className={0 === chartState ? 'background-button button-active' : 'button-blue'}
                                onClick={() => setChart(0)}>{translate.charts.chart1.title}</button>
                        <button key={Math.random()}
                                className={1 === chartState ? 'background-button button-active' : 'button-blue'}
                                onClick={() => setChart(1)}>{translate.charts.chart2.title}</button>
                        {/*<button key={Math.random()}*/}
                        {/*        className={2 === chartState ? 'background-button button-active' : 'button-blue'}*/}
                        {/*        onClick={() => setChart(2)}>{translate.charts.chart3.title}</button>*/}
                    </div>
                </div>

                {
                    chartState === 0
                        ? <div>
                            <p className='charts-description'>{translate.charts.chart1.description}</p>
                            <p className='charts-description'>{translate.charts.chart1.additionalDescription}</p>
                        </div>
                        : null
                }

                {chartState === 1 ? <p className='charts-description'>{translate.charts.chart2.description}</p> : null}

                {chartState === 2 ? <p className='charts-description'>{translate.charts.chart3.description}</p> : null}

                <div className='charts-block'>
                    {chartState === 0 ? <ChartOne/> : null}
                    {chartState === 1 ? <ChartTwo/> : null}
                    {chartState === 2 ? <ChartThree/> : null}
                </div>
            </div>
        </div>
    );
};

export default Charts;
