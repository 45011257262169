import React, {useContext, useLayoutEffect, useRef} from 'react';
import './WhatWeDo.scss'
import Heading from "../atoms/Heading/Heading";
import {TranslateContext} from "../../App";

const WhatWeDo = (props: {animation: boolean}) => {
    const translate = useContext(TranslateContext);

    const img = useRef<HTMLDivElement>(null);
    const heading = useRef<HTMLDivElement>(null);

    const wedoOne = useRef<HTMLDivElement>(null);
    const wedoTwo = useRef<HTMLDivElement>(null);
    const wedoThree = useRef<HTMLDivElement>(null);


    let observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            if (img.current &&  heading.current && wedoOne.current && wedoTwo.current && wedoThree.current) {
                if (entry.intersectionRatio > 0.1) {
                    img.current.style.transform = `translate3d(0,0,0)`
                    heading.current.style.opacity = '1'
                    wedoOne.current.style.opacity = '1'
                    wedoTwo.current.style.opacity = '1'
                    wedoThree.current.style.opacity = '1'
                    wedoOne.current.style.transform = `translate3d(0,0,0)`
                    wedoTwo.current.style.transform = `translate3d(0,0,0)`
                    wedoThree.current.style.transform = `translate3d(0,0,0)`
                }

                if (entry.intersectionRatio < 0.9) {
                    img.current.style.transform = `translate3d(-1000px,0,0)`
                    heading.current.style.opacity = '0'
                    wedoOne.current.style.transform = `translate3d(100vw,0,0)`
                    wedoTwo.current.style.transform = `translate3d(100vw,0,0)`
                    wedoThree.current.style.transform = `translate3d(100vw,0,0)`
                    wedoOne.current.style.opacity = '0'
                    wedoTwo.current.style.opacity = '0'
                    wedoThree.current.style.opacity = '0'
                }
            }
        });
    });


    useLayoutEffect(() => {
        if (props.animation){
            let elShoe = document.querySelector('.wedo-wrapper');
            // @ts-ignore
            observer.observe(elShoe);
        }
    })

    return (
        <div className='wedo-wrapper' id="about-solution">
            <div className='wedo'>
                <div className={props.animation ? 'wedo-img' : 'wedo-img non-animation'}  ref={img}>
                    <div className='wedo-info'>
                        <div ref={heading} className={props.animation ? 'wedo-heading' : 'wedo-heading non-animation'}>
                            <Heading text={translate.whatWeDo.heading}/>
                        </div>

                        <p className='project-description'>{translate.whatWeDo.description}</p>

                        <div className='wedo-lists'>
                            <div className='wedo-list'>
                                <p className='wedo-list-label'>
                                    {translate.whatWeDo.prosLabel}
                                </p>
                                <div className={props.animation ? 'point-wrapper' : 'point-wrapper non-animation'} ref={wedoOne}>
                                    <div className='point'></div>
                                    <p>{translate.whatWeDo.pros1}</p>
                                </div>
                                <div className={props.animation ? 'point-wrapper' : 'point-wrapper non-animation'} ref={wedoTwo}>
                                    <div className='point'></div>
                                    <p>{translate.whatWeDo.pros2}</p>
                                </div>
                                <div className={props.animation ? 'point-wrapper' : 'point-wrapper non-animation'} ref={wedoThree}>
                                    <div className='point'></div>
                                    <p>{translate.whatWeDo.pros3}</p>
                                </div>
                            </div>

                            <div className='wedo-list'>
                                <p className='wedo-list-label'>
                                    {translate.whatWeDo.descriptionLabel}
                                </p>
                                <div className={props.animation ? 'point-wrapper' : 'point-wrapper non-animation'} ref={wedoOne}>
                                    <div className='point'></div>
                                    <p>{translate.whatWeDo.description1}</p>
                                </div>
                                <div className={props.animation ? 'point-wrapper' : 'point-wrapper non-animation'} ref={wedoTwo}>
                                    <div className='point'></div>
                                    <p>{translate.whatWeDo.description2}</p>
                                </div>
                                <div className={props.animation ? 'point-wrapper' : 'point-wrapper non-animation'} ref={wedoThree}>
                                    <div className='point'></div>
                                    <p>{translate.whatWeDo.description3}</p>
                                </div>
                                <div className={props.animation ? 'point-wrapper' : 'point-wrapper non-animation'} ref={wedoThree}>
                                    <div className='point red'></div>
                                    <p><b>{translate.whatWeDo.description4}</b></p>
                                </div>
                            </div>
                        </div>

                        <p className='post-description'><i>{translate.whatWeDo.postDescription}</i></p>

                    </div>
                </div>

            </div>
        </div>
    );
};

export default WhatWeDo;
