import React from 'react';
import './ReviewCard.scss'
import { ReviewModel } from '../Reviews';


const ReviewCard = (props: {review: ReviewModel}) => {
    return (
        <div className='review-card-wrapper'>
            <p className='review-description'>
                <b><i>{props.review.description}</i></b>
            </p>

            <p className='review-author'>
                {props.review.author}
            </p>
        </div>
    );
};

export default ReviewCard;
