import React, { createContext, Suspense, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import './App.css';
import Main from './components/Main/Main';
import AboutSolut from "./components/AboutSolut/AboutSolut";
import Sphere from "./components/Sphere/Sphere";
import HowItsWork from "./components/HowItsWork/HowItsWork";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Scroll from './utils/scroll/scroll';
import isMobile from "ismobilejs";
import classNames from 'classnames';
import Transition from './components/transition/transition';
import SolveProblem from './components/SolveProblem/SolveProblem';
import Results from "./components/Results/Results";
import WhatWeDo from "./components/WhatWeDo/WhatWeDo";
import Payback from "./components/Payback/Payback";
import Partners from "./components/Partners/Partners";
import Contacts from "./components/Contacts/Contacts";
import Map from "./components/Map/Map";
import OtherSystems from './components/OtherSystems/OtherSystems';
import Realization from "./components/Realization/Realization";
import Form from './components/Form/Form';
import Footer from "./components/Footer/Footer";
import Charts from "./components/Charts/Charts";
import { gb, ru } from "./assets/translates";
import HowItsWorkStatic from './components/HowItsWorkStatic/HowItsWorkSctatic';
import Documents from './components/Documents/Documents';
import Projects from './components/Projects/Projects';
import Reviews from './components/Reviews/Reviews';
import ScreenshotsAndTechnologies from './components/ScreenshotsAndTechnologies/ScreenshotsAndTechnologies';

declare global {
    interface Window {
        core: {
            scrollTop: number;
            scrollCustom: boolean;
        }
    }
}

export const TranslateContext = createContext(ru);

const initialStateHeight = {
    about: {
        heightTo: 0,
        percentShow: 0,
    },
    sphere: {
        heightTo: 0,
        percentShow: 0,
    },
    how: {
        heightTo: 0,
        percentShow: 0,
    },
    solveProblem: {
        heightTo: 0,
        percentShow: 0,
    },
    weDo: {
        heightTo: 0,
        percentShow: 0,
    },
}


const App: React.FC<{}> = () => {
    const [scrollHeight, setScrollHeight] = useState(0);
    const [pathScroll, setPathScroll] = useState(0);
    const [animation, setAnimation] = useState<boolean>(false);

    const [heightElements, setHeightElements] = useState(initialStateHeight)


    useEffect(() => {

        // console.log(scrollHeight)
        //     setAnimation(false)
        // } else {
        //     setAnimation(true)
        // }
    })

// сколько процентов блока показывается на экране
//      до элемента - 0
//     entry.intersectionRatio

    // entry.boundingClientRect.y
    // показывает значение высоты до элемента, если пролистать то будет отрицательное значение.
    // когда низ элемента на экране - 0

    // const [scrollTo, setScrollTo] = useState<null | number>(null)
    //
    //
    // useEffect(()=>{
    //     console.log(scrollHeight)
    //     const a = document.querySelector('#about-solut')
    //     if (a){
    //         console.log(a.scrollHeight)
    //     }
    // })

    window.core = {
        scrollTop: 0,
        scrollCustom: !isMobile().any,
    }
    //
    // const scrollableRef = useRef<HTMLDivElement>(null);
    // const wheel = useRef<number>(0);
    //
    //
    // const history = useHistory();
    // const locationTimeout = useRef<any>();
    // const transitionTimeout = useRef<any>();
    const tTransition = 500;


    // let observer = new IntersectionObserver(function (entries) {
    //     entries.forEach(function (entry) {
    //     });
    // });

    useLayoutEffect(() => {

        // const about = document.querySelector('.about')
        // const sphere = document.querySelector('.spheres')
        // const how = document.querySelector('.how-work')
        // const solveProblem = document.querySelector('.solwe')
        // const weDo = document.querySelector('.wedo-wrapper')
        // // @ts-ignore
        // console.log('lalala', about?.getBoundingClientRect().top, about?.clientTop, about?.scrollTop)
        //
        //
        // let elShoe = document.querySelector('.content');
        // // @ts-ignore
        // observer.observe(elShoe);
    })


    window.addEventListener('popstate', (e) => {
        // @ts-ignore
        const a = document.querySelector('#payback').clientTop
        // @ts-ignore
        const b = document.querySelector('#payback').clientHeight
        // @ts-ignore
        const w = document.querySelector('#about-solut-atomic').getBoundingClientRect().y
        // @ts-ignore
        const d = document.querySelector('#payback').getBoundingClientRect().y

        // const name = e.newURL.indexOf('#')
    })

    return (
        <TranslateContext.Provider value={ru}>
            <Router>
                <Switch>
                    <Route path="/documents">
                        <Documents/>
                    </Route>
                    <Route path="/">
                        <div className={classNames("App")} onWheel={(e) => setScrollHeight(e.deltaY)}>
                            {/*<Transition isOpen={transition || menuOpen} duration={tTransition}/>*/}
                            <div className='main-block'>
                                {/*<div className='main-header'>*/}
                                {/*    <Header/>*/}
                                {/*</div>*/}
                                <Main/>
                            </div>
                            {/*<Scroll getScroll={(e)=>setScrollHeight(e)}>*/}
                            <div className='all-blocks'>
                                <AboutSolut animation={animation}/>
                                <WhatWeDo animation={animation}/>
                                <Sphere animation={animation}/>
                                <HowItsWorkStatic animation={true} scroll={scrollHeight}/>
                                <ScreenshotsAndTechnologies animation={animation}/>
                                {/* <SolveProblem animation={animation}/> */}
                                {/* <OtherSystems animation={animation}/> */}
                                {/* <Realization animation={animation}/> */}
                                <Charts animation={animation}/>
                                <Payback animation={animation}/>
                                <Projects animation={animation}/>
                                <Reviews animation={animation}/>
                                {/* <Map animation={animation}/> */}
                                {/*<Results animation={animation}/>*/}
                                {/* <Partners animation={animation}/> */}
                                <Contacts animation={animation}/>
                                {/*<Form animation={animation}/>*/}
                                <Footer animation={animation}/>
                            </div>
                        </div>
                    </Route>
                </Switch>
            </Router>
        </TranslateContext.Provider>
    );
}

export default App;
